import React from 'react';
import SEO from 'components/seo';

// Component
import Hero from 'components/molecules/Hero/Hero';
import TextImageBlock from 'components/organisms/Blocks/TextImageBlock/TextImageBlock';

// Data
import OverOnsData from 'data/OverOnsPage.json';

const OverOnsPage = () => (
  <>
    <SEO title="Over ons" />
    <TextImageBlock
      data={OverOnsData.intro}
    />
    <Hero
      modifier="inPage"
      {...OverOnsData.inPageHero}
    />
  </>
);
export default OverOnsPage;
